import {
    Alert,
    AlertTitle,
    Grid,
    GridProps,
    LinearProgress,
    Paper,
    Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { GET } from "../../../Utilities/BaseService";
import { BillCalculations } from "./Cells/BillCalculations";
import { Cell } from "./Cells/Cell";
import { Last12Summary } from "./Cells/Last12Summary";
import { OfficeCopy } from "./Cells/OfficeCopy";
import { ReadingImage } from "./Cells/ReadingImage";
import { Header } from "./Header";

type Props = {
    _bill?: Bill;
    gridProps?: GridProps;
    includeOfficeCopy?: boolean;
};

export const dateFormatter = Intl.DateTimeFormat("en", {
    day: "2-digit",
    month: "short",
    year: "numeric",
});

export const monthFormatter = Intl.DateTimeFormat("en", {
    month: "short",
    year: "numeric",
});

const fetchBill = async (id: string | number) => {
    try {
        const response = await GET("/reading/bill/generate", {
            readingIds: id,
        });
        if (response.data?.rows?.length > 0) {
            const singleReading = response.data.rows[0];
            return singleReading;
        }
        throw Error("No reading matching the ID");
    } catch (err) {
        throw err;
    }
};

const isTermianatable = (id: string) => {
    return GET("/customer/meter/is-terminatable", { id });
};

type MeterAttribute = {
    type: "collection" | string;
    id: number;
    createdAt: string;
    value: string;
};

export const SingleBill = ({
    _bill,
    gridProps,
    includeOfficeCopy = true,
}: Props) => {
    const location = useLocation();
    const [bill, setBill] = useState<Bill | null>(null);

    const [id, setId] = useState<any>(null);
    const { status } = useQuery(["bill", id], () => fetchBill(id), {
        enabled: Boolean(id),
        onSuccess(b) {
            if (b) {
                setBill(b);
            }
        },
    });

    const { data: response, status: terminationStatus } = useQuery(
        ["isTerminatable"],
        () => isTermianatable(bill.meter.id),
        {
            enabled: Boolean(bill),
        }
    );

    const collectionAttributes = useMemo(
        () =>
            _bill.meter.attributes.filter(
                (x: MeterAttribute) =>
                    x.type === "collection" &&
                    new Date(x.createdAt) < new Date(_bill.readingDate)
            ),
        [_bill]
    );

    useEffect(() => {
        if (!_bill) {
            const params = new URLSearchParams(location.search);
            const _id = params.get("id");
            if (_id) {
                setId(_id);
            }
        } else {
            setBill(_bill);
        }
    }, [location.search]);

    return (
        <Grid
            item
            container
            xs={12}
            {...gridProps}
            component={Paper}
            elevation={0}
            sx={{ width: "100%" }}
            p={1}
        >
            <Header copyName="Consumer copy" />
            {bill ? (
                <Grid item container xs={12}>
                    <Cell
                        label="Reference no."
                        labelUrdu="حوالہ نمبر"
                        value={bill.id}
                        xs={2}
                        sx={{ border: "2px solid", borderRight: "0px" }}
                    />
                    <Cell
                        label="Connection Date"
                        labelUrdu="تاریخ کنکشن"
                        value={dateFormatter.format(
                            new Date(bill.meter.connectionDate)
                        )}
                        sx={{ border: "2px solid", borderRight: "0px" }}
                    />
                    <Cell
                        label="Billing Month"
                        labelUrdu="مہینہ بل"
                        value={monthFormatter.format(
                            new Date(bill.year, bill.month - 1, 1)
                        )}
                        sx={{ border: "2px solid", borderRight: "0px" }}
                    />
                    <Cell
                        label="Reading Date"
                        labelUrdu="تاریخ ریڈنگ"
                        value={dateFormatter.format(new Date(bill.readingDate))}
                        sx={{ border: "2px solid", borderRight: "0px" }}
                    />
                    <Cell
                        label="Issue Date"
                        labelUrdu="تاریخ اجراء"
                        value={dateFormatter.format(new Date(bill.issueDate))}
                        sx={{ border: "2px solid", borderRight: "0px" }}
                    />
                    <Cell
                        label="Due Date"
                        labelUrdu="مقرره تاریخ"
                        value={dateFormatter.format(new Date(bill.dueDate))}
                        sx={{ border: "2px solid" }}
                        labelSx={{ color: "red" }}
                    />

                    <Cell
                        xs={4}
                        sx={{
                            border: "2px solid",
                            borderTop: "0px",
                            borderRight: "1px solid",
                        }}
                        noTypography
                        label="Name & Address"
                        value={
                            <div>
                                <Typography variant="body2">
                                    <b>Name:</b>{" "}
                                    {bill.meter.property.customer.name}
                                </Typography>
                                <Typography variant="body2">
                                    <b>Father name:</b> S/O{" "}
                                    {bill.meter.property.customer.fatherName}
                                </Typography>
                                <Typography variant="body2">
                                    <b>CNIC</b>{" "}
                                    {bill.meter.property.customer.cnic}
                                </Typography>
                                <Typography variant="body2">
                                    <b>Property Name: </b>{" "}
                                    {bill.meter.property.name}
                                </Typography>
                                <Typography variant="body2">
                                    <b>Property Address: </b>{" "}
                                    {bill.meter.property.address}
                                </Typography>
                            </div>
                        }
                    />
                    <Cell
                        sx={{
                            borderBottom: "2px solid",
                            borderLeft: "1px solid",
                            borderRight: "1px solid",
                        }}
                        xs={4}
                        noTypography
                        noLabel
                        value={
                            <div
                                style={{
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    height: "100%",
                                }}
                            >
                                <Typography variant="body2" fontWeight="bold">
                                    {bill.meter.powerstation.name}
                                </Typography>
                                <Typography variant="caption">
                                    ({bill.meter.connectionType.name})
                                </Typography>
                            </div>
                        }
                    />
                    <Cell
                        xs={4}
                        noLabel
                        noTypography
                        value={<ReadingImage reading={bill} />}
                        sx={{
                            borderBottom: "2px solid",
                            borderRight: "2px solid",
                            borderLeft: "1px solid",
                        }}
                    />

                    <Cell
                        label="Meter No."
                        labelUrdu="میٹرنمبر"
                        value={bill.meter.meterNo}
                        xs={4}
                        sx={{
                            borderBottom: "2px solid",
                            borderLeft: "2px solid",
                        }}
                    />

                    <Cell
                        label="Prev. reading"
                        labelUrdu="سابقہ ریڈنگ"
                        value={bill.prevUnits}
                        xs={2}
                        sx={{
                            borderBottom: "2px solid",
                            borderLeft: "2px solid",
                        }}
                    />
                    <Cell
                        label="Current reading"
                        labelUrdu="موجوده ریڈنگ"
                        value={bill.currentUnits}
                        xs={2}
                        sx={{
                            borderBottom: "2px solid",
                            borderLeft: "2px solid",
                            borderRight: "1px solid",
                        }}
                    />
                    <Cell
                        label="Units consumed"
                        labelUrdu="صرف شدو یونٹ"
                        value={bill.currentUnits - bill.prevUnits + ""}
                        xs={4}
                        sx={{
                            borderBottom: "2px solid",
                            borderLeft: "1px solid",
                            borderRight: "2px solid",
                        }}
                    />

                    <Cell
                        xs={8}
                        label="Previous Record"
                        noTypography
                        value={
                            <Last12Summary
                                last12Readings={collectionAttributes}
                            />
                        }
                        sx={{
                            borderBottom: "2px solid",
                            borderLeft: "2px solid",
                        }}
                    />

                    <Cell
                        xs={4}
                        // container
                        noTypography
                        value={<BillCalculations reading={bill} />}
                        label="Bill calculations"
                        labelUrdu="بل کاحساب"
                        sx={{
                            borderBottom: "2px solid",
                            borderLeft: "2px solid",
                            borderRight: "2px solid",
                            flexDirection: "column",
                            textAlign: "left",
                        }}
                    />

                    {terminationStatus === "success" &&
                        response?.data.terminatable && (
                            <Grid item xs={12} paddingTop={2}>
                                <Typography
                                    color="red"
                                    textAlign="center"
                                    fontWeight="bold"
                                >
                                    معزذ صارف اپنے بقایاجات کو مقرره تاریخ تک
                                    ادا کر دیں۔ بصورت دیگر آپ کے کنکشن کو منقطع
                                    کر دیا جائے گا۔
                                </Typography>
                            </Grid>
                        )}

                    <Grid
                        item
                        xs={12}
                        sx={{
                            textAlign: "center",
                            m: 2,
                            overflow: "hidden",
                            height: "1rem",
                        }}
                    >
                        {Array(20).fill("-").join("") + " "}
                        {Array(20).fill("-").join("") + " "}
                        {Array(20).fill("-").join("") + " "}
                        {Array(20).fill("-").join("") + " "}
                        {Array(20).fill("-").join("") + " "}
                        {Array(20).fill("-").join("") + " "}
                        {Array(20).fill("-").join("") + " "}
                    </Grid>

                    {includeOfficeCopy && <OfficeCopy reading={bill} />}
                </Grid>
            ) : status === "loading" ? (
                <Grid item xs={12}>
                    <LinearProgress />
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <Alert severity="warning">
                        <AlertTitle title="Not found!" />
                        Cannot find the reading provided
                    </Alert>
                </Grid>
            )}
        </Grid>
    );
};

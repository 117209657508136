import { Download, ReplayOutlined } from "@mui/icons-material";
import {
    Alert,
    Box,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    LinearProgress,
    Paper,
    Radio,
    RadioGroup,
    TablePagination,
    useTheme,
} from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../Utilities/AppContext";
import { GET } from "../../../Utilities/BaseService";
import { S3Service } from "../../../Utilities/S3Service";
import { awsDirStruct } from "../../../Utilities/constants";
import { BillFilter } from "./BillFilter";
import BillsPdf from "./BillsPdf";
import { SingleBill } from "./SingleBill";

const fetchBills = async (filter: any) => {
    return await GET("/reading/bill/generate", filter);
};

export type Filter = {
    district: number | string;
    village: number | string;
    hamlet: number | string;
    powerstation: number | string;
    id: number | string;
    month: string;
    year: string;
    meterNo: (string | number)[];
};

export const Bills = () => {
    const printRef = useRef<any>();
    const [pagination, setPagination] = useState({
        page: 0,
        count: 0,
        limit: 100,
    });
    const [count, setCount] = useState(0);
    const [filter, setFilter] = useState<Filter>({
        district: "all",
        village: "all",
        hamlet: "all",
        powerstation: "all",
        id: "",
        month: "",
        year: "",
        meterNo: [],
    });
    const [ids, setIds] = useState<(string | number)[]>([]);
    const [showBills, setShowBills] = useState(false);
    // const [printMode, setPrintMode] = useState(false);
    const [logoSrc, setLogoSrc] = useState("");
    const [generatePdf, setGeneratePdf] = useState(false);
    const [groupBillsBy, setGroupBillsBy] = useState<
        "village" | "powerstation"
    >("village");
    const { store, setStore } = useContext(AppContext);
    const qc = useQueryClient();
    const theme = useTheme();

    const { data: billsResponse, fetchStatus: billStatus } = useQuery(
        ["bills", showBills, filter, pagination.page, pagination.limit, count],
        () =>
            fetchBills({
                ...Object.fromEntries(
                    Object.entries(filter).filter((x) => x[1] && x[1] !== "all")
                ),
                page: pagination.page + 1,
                limit: pagination.limit,
            }),
        {
            enabled: showBills,
            onSuccess(res) {
                setPagination({ ...pagination, count: res.data.count });
                if (billsResponse?.data.rows.length > 0) {
                    const bills = billsResponse?.data.rows;
                }
            },
        }
    );

    const getLogo = async () => {
        if (store.logoURL) {
            setLogoSrc(store.logoURL);
        } else {
            try {
                const response = await GET("/settings/logos", {
                    default: true,
                });
                const firstDefault = response.data.rows[0];
                const [{ url }, err] = await S3Service.getS3ByKey(
                    `${awsDirStruct.logos}/${firstDefault.id}`
                );

                // const _response = await fetch(url);
                // const blob = await _response.blob();
                // const blobURL = URL.createObjectURL(blob);
                setLogoSrc(url);
                setStore({ ...store, logoURL: url });
            } catch (err) {}
        }
    };

    useEffect(() => {
        if (filter.meterNo.length) {
            qc.invalidateQueries(["bills", ids, showBills]);
        } else setIds([]);
    }, [filter]);

    useEffect(() => {
        getLogo();
    }, []);

    return (
        <Grid container spacing={1}>
            <BillFilter
                filter={filter}
                setFilter={setFilter}
                isLoading={false}
                setShowBills={setShowBills}
            />

            <Grid item xs={12}>
                <FormControl>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <FormLabel>Group bills by:</FormLabel>
                        </Grid>

                        <Grid item>
                            <RadioGroup>
                                <Grid container>
                                    <Grid item>
                                        <FormControlLabel
                                            label="Village"
                                            onChange={() =>
                                                setGroupBillsBy("village")
                                            }
                                            control={
                                                <Radio
                                                    checked={
                                                        groupBillsBy ===
                                                        "village"
                                                    }
                                                    value="village"
                                                />
                                            }
                                        />
                                    </Grid>

                                    <Grid item>
                                        <FormControlLabel
                                            label="Power Station"
                                            onChange={() =>
                                                setGroupBillsBy("powerstation")
                                            }
                                            control={
                                                <Radio
                                                    checked={
                                                        groupBillsBy ===
                                                        "powerstation"
                                                    }
                                                    value="powerstation"
                                                />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </FormControl>
            </Grid>

            <Grid
                item
                xs={12}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Box display="flex" alignItems="center" gap={theme.spacing(1)}>
                    {/* <Print
                        componentRef={printRef}
                        setPrintMode={setPrintMode}
                    /> */}

                    <IconButton
                        size="small"
                        title="Reload View"
                        onClick={() => setCount(count + 1)}
                    >
                        <ReplayOutlined fontSize="small" />
                    </IconButton>

                    {generatePdf ? (
                        <BillsPdf
                            logoSrc={logoSrc}
                            groupBy={groupBillsBy}
                            bills={billsResponse?.data.rows || []}
                            showBills={showBills}
                        />
                    ) : (
                        <IconButton
                            size="small"
                            onClick={
                                showBills
                                    ? () => setGeneratePdf(true)
                                    : undefined
                            }
                            disabled={!showBills}
                        >
                            <Download fontSize="small" />
                        </IconButton>
                    )}
                </Box>

                {/*    <BlindPagination
                    pagination={pagination}
                    setPagination={setPagination}
                /> */}

                <TablePagination
                    component="div"
                    count={pagination.count}
                    page={pagination.page}
                    onPageChange={(e, newPage) =>
                        setPagination({ ...pagination, page: newPage })
                    }
                    rowsPerPage={pagination.limit}
                    onRowsPerPageChange={(e) =>
                        setPagination({
                            ...pagination,
                            limit: parseInt(e.target.value),
                        })
                    }
                />
            </Grid>

            <Grid item xs={12}>
                <Divider flexItem />
            </Grid>

            <Grid item xs={12}>
                {billStatus === "fetching" && <LinearProgress />}
            </Grid>

            <Grid item xs={12}>
                {billsResponse?.data.rows.length === 0 && (
                    <Alert severity="info">No bills found</Alert>
                )}
            </Grid>

            <Grid
                item
                container
                xs={12}
                component={Paper}
                ref={printRef}
                elevation={0}
            >
                {showBills &&
                    billsResponse?.data.rows.map((bill: Bill) => (
                        <SingleBill
                            key={bill.id}
                            _bill={bill}
                            gridProps={{
                                mb: 5,
                            }}
                        />
                    ))}
            </Grid>
        </Grid>
    );
};
